import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'

export function get_node_tara(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/taraAnalysisDetails`,
        method: 'get',
    })
}
export function init_node_tara(params, data) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/taraAnalysisDetails`,
        method: 'post',
        data: data
    })
}
export function del_node_tara(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/taraAnalysisDetails/${params.id}`,
        method: 'DELETE',
    })
}
export function chenge_node_tara(params, data) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/taraAnalysisDetails/${params.id}`,
        method: 'PUT',
        data: data
    })
}
export function chenge_node_whether(params, data) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/taraAnalysisDetails/whether`,
        method: 'PUT',
        data: data
    })
}
export function get_node_whether(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/taraAnalysisDetails/whether`,
        method: 'get',
    })
}
export function get_all_node(params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${params.projectId}/files/${params.fileKey}/taraAnalysisDetailsTable`,
        method: 'get',
    })
}

export function get_allRiskAnalysisPage(projectId, params) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${projectId}/tara/allRiskAnalysisPage?sort=id%2Cdesc&`,
        method: 'get',
        params,
    })
}

export function get_pic(projectId, dimension) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${projectId}/tara/statistics?dimension=${dimension}`,
        method: 'get',
    })
}

export function notice(projectId, nodeKey, detailId) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/${nodeKey}/taraAnalysisDetails/${detailId}/notification`,
        method: 'post',
    })
}
export function buy_tara(id) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/taraFeature/effective?fileId=${id}`,
        method: 'get',
    })
}

export function get_CustomizeOptions(projectId, type) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${projectId}/tara/customizeOptions?type=${type}`,
        method: 'get',
    })
}

export function put_CustomizeOptions(projectId, data) {
    return request({
        url: `/${serviceConfig['file-manage-service']}/${projectId}/tara/customizeOptions`,
        method: 'put',
        data
    })
}
