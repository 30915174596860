import request from "@/utils/request";
import serviceConfig from '@/network/serviceConfig'
// 获取节点详细信息
// tag 无影响
export function get_node_detail(projectId, nodeId) {
  // 这个nodeId与nodePrimaryId区别是什么？
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/${nodeId}`,
    method: "get",
  });
}
//获取文件详情
export function get_file(projectId, fileId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/fileId/${fileId}`,
    method: "get",
  });
}

// 根据 nodeKey 获取节点是否存在
export function get_node_use_node_exist(projectId, nodeKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/${nodeKey}/fetch`,
    method: "get",
  });
}

// 根据 node key 获取结点信息
// tag 无影响
export function get_node_use_node_key(projectId, nodeKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/key/${nodeKey}`,
    method: "get",
  });
}



// 模糊搜索节点
// tag 无影响
export function get_nodes_by_fuzzykey(projectId, fuzzyKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes`,
    method: "get",
    params: {
      fuzzyKey,
    },
  });
}

export function get_nodes_by_fuzzykey_testCases(projectId, fuzzyKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/testCaseNodes`,
    method: "get",
    params: {
      fuzzyKey,
    },
  });
}

// 模糊sprint搜索节点
export function get_nodes_by_fuzzykey_sprint(projectId, fuzzyKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/sprintNodeRelation/nodes`,
    method: "get",
    params: {
      fuzzyKey,
    },
  });
}
// 模糊KANBAN搜索节点
export function get_nodes_by_fuzzykey_KANBAN(projectId, fuzzyKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/kanbanNodeRelation/nodes`,
    method: "get",
    params: {
      fuzzyKey,
    },
  });
}
// 获取可转换目标状态列表
export function get_workflow_list({
  projectId,
  fileTypeId,
  layer,
  currentStatusId,
}) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/fileTypeLayers/${fileTypeId}/layers/${layer}/targetStatusList`,
    method: "get",
    params: { currentStatusId },
  });
}

// 编辑节点状态（工作流）
export function set_workflow(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/node/status`,
    method: "put",
    data,
  });
}

// 编辑节点浮窗字段
export function set_property(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/node/fields`,
    method: "put",
    data,
  });
}
// 新的修改字段接口 只传需要修改的字段
export function only_set_property(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/${params.nodeKey}/field`,
    method: "put",
    data: params.data,
  });
}
export function get_allProjectMember(projectId) {
  //获取项目中所有的成员
  return request({
    url: `/${serviceConfig['project-service']}/allProjectMember?projectId=${projectId}`,
    method: "get",
  });
}

// 获取标签
export function get_Labels(projectId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/node/labelOptionList`,
    method: "get",
  });
}

// 上传节点附件
export function upload_excel(projectId, params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/excelResolution`,
    method: "post",
    params: params,
  });
}
// 导入word
export function createFileWithWord(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/createFileWithWord`,
    method: "post",
    data,
  });
}
export function upload_excel_key(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/createFileWithExcel`,
    method: "post",
    data: data,
  });
}
// 导入xmind
export function uploadXmind(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/import/xmind/files`,
    method: "post",
    data: data,
  });
}
// freemind
export function uploadFreemind(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/import/freemind/files`,
    method: "post",
    data: data,
  });
}
export function download_excel(projectId, fileKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/${fileKey}/excel`,
    responseType: "blob",
    headers: {
      "Content-Type": "application/json;application/octet-stream",
    },
    method: "get",
  });
}
// 富文本框上传文件
export function upload_file(file) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/mediaFile`,
    method: "post",
    data: file,
  });
}
// 获取关联透视图
export function get_relation_data(projectId, nodeKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/key/${nodeKey}/nodeRelations`,
    method: "get",
  });
}

// 添加关联节点
export function add_relation_node(projectId, nodeKey, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/key/${nodeKey}/nodeRelations`,
    method: "post",
    data,
  });
}
// 批量添加关联节点
export function add_batch_relation_node(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/nodeRelations`,
    method: "post",
    data: params.data,
  });
}
//fuzzyKey=XXX

// 获取未关联的节点
// tag 无影响
export function get_relation_node(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes`,
    method: "get",
    params: {
      fuzzyKey: data,
    },
  });
}

//取消关联节点
export function cancel_relation_node(projectId, nodeKey, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/key/${nodeKey}/nodeRelations`,
    method: "delete",
    data,
  });
}
//批量修改节点状态
export function multiLayers_change_status(params) {
  let d = {}
  d['nodeKeys'] = params.nodeKeys
  d['updateStatusDtoList'] = params.data
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/batch/status`,
    method: "put",
    data: d,
  });
}
//批量修改节点责任人
export function multiLayers_change_assignee(params) {
  let d = params.data
  d['nodeKeys'] = params.nodeKeys
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/batch/assignee`,
    method: "put",
    data: d,
  });
}
// 文件内移动节点
export function node_location_change(params) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/nodes/${params.nodeKey}/location`,
    method: "put",
    data: params.data,
  });
}
// // 跨文件移动节点
export function move_nodes_though_files(
  projectId,
  sourceNodeKey,
  sourceFileId,
  layer,
  targetNodeKey,
  targetFileId,
  sourceNodeId
) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/nodes/differentFileLocation`,
    method: "put",
    data: {
      sourceNodeKey,
      sourceFileId,
      layer,
      targetNodeKey,
      targetFileId,
      sourceNodeId
    },
  });
}

export function get_nodeHistory(projectId, nodeUuid) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodeHistory/${nodeUuid}`,
    method: "get",
  });
}

export function get_node_versions(projectId, nodeKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/versions/nodeDistribution/${nodeKey}`,
    method: "get",
  });
}

export function get_node_sprints(projectId, nodeKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/sprints/nodeDistribution/${nodeKey}`,
    method: "get",
  });
}
// tag 无影响
export function get_node_testPlans(projectId, nodeKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/testPlans/nodeDistribution/${nodeKey}`,
    method: "get",
  });
}
export function get_node_baselines(projectId, nodeKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/baselines/nodeDistribution/${nodeKey}`,
    method: "get",
  });
}

export function get_node_histories(projectId, nodeUuid) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/${nodeUuid}/histories`,
    method: "get",
  });
}

export function updateBaselineBatch(projectId, nodeKey, baselineIds) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/${nodeKey}/baselines`,
    method: "put",
    data: baselineIds,
  });
}

// 添加关联节点
export function get_authorization() {
  return request({
    url: `/${serviceConfig['file-manage-service']}/stsCredentials`,
    method: "get",
  });
}

export function get_node_nodeOriginInfo(projectId, nodeKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/nodes/key/${nodeKey}/nodeOriginInfo`,
    method: "get",
  });
}

export function copy_node(projectId, data) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/nodes/copyPaste`,
    data: data,
    method: "post",
  });
}
export function copyMultiSelectNodeList(projectId, data) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/nodes/copyMultiSelectNodeList`,
    data: data,
    method: "post",
  });
}
// 手动推状态弹窗
export function set_workflow_myself(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/workflow/validator/passSituation`,
    method: "post",
    data
  });
}
// 获取批量修改字段的所有字段
export function getBatchField(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/batch/field/choices`,
    method: "get"
  });
}
// 批量修改字段的所有字段
export function putBatchField(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/batch/field`,
    method: "PUT",
    data: params.data
  });
}

export function change_node_fta_door(projectId, nodeKey, door) {
  //编辑节点fta门
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/fta/nodes/${nodeKey}/gates?gateType=${door}`,
    method: "put",
  });
}

export function new_node_fta_door(projectId, nodeKey, door) {
  //创建节点fta门
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/fta/nodes/${nodeKey}/gates?gateType=${door}`,
    method: "post",

  });
}

export function get_file_fta_door(projectId, fileKey) {
  //获取节点fta门
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/fta/files/${fileKey}/gates`,
    method: "get",
  });
}

export function get_file_fta_probability(projectId, fileKey) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/fta/files/${fileKey}/probability`,
    method: "get",
  });
}
export function put_file_fta_probability(projectId, fileKey, data) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/fta/files/${fileKey}/probability`,
    method: "put",
    data: data
  });
}

export function effective(fileId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/ftaFeature/effective?fileId=${fileId}`,
    method: "get",
  });
}


// 设置底事件类型
export function put_file_fta_leaf_door(projectId, fileKey, data) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/fta/files/${fileKey}/eventType`,
    method: "put",
    data: data
  });
}

// 获取底事件类型
export function get_file_fta_leaf_door(projectId, fileKey) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${projectId}/fta/files/${fileKey}/eventType`,
    method: "get",
  });
}
// 同步源节点
export function sourceNodeSync(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/sourceNodeSync`,
    method: "put",
    data: params.data
  });
}
// 原节点复用记录
export function nodeOriginLog(params) {
  return request({
    url: `/${serviceConfig['mindmap-service-v2']}/${params.projectId}/nodes/${params.nodeKey}/nodeOriginLog`,
    method: "get",
  });
}
// 高级搜索校验
export function mqlValidation(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/nodeQuery/mql/validation`,
    method: "post",
    data: params.data
  });
}
// 高级搜索
export function mqlFilter(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${params.projectId}/nodes/mqlFilter?pageSize=${params.pageSize}&pageNumber=${params.pageNumber}`,
    method: "post",
    data: params.data
  });
}